import { useState } from 'react';
import '../../styles/landingPage/footer.css';
import learnspaceLogo from '../../assets/landingPageImages/learnspaceLogo.svg';
import { Typography, Box, Grid, useMediaQuery } from '@mui/material';
import EmailIcon from 'assets/landingPageImages/EmailIcon.svg';
import Facebook from 'assets/landingPageImages/Facebook.svg';
import Twitter from 'assets/landingPageImages/Twitter2.svg';
import { useTheme } from '@mui/material/styles';
import { BsPlus } from 'react-icons/bs';
import { BsChevronDown, BsX } from 'react-icons/bs';

const Footer = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [show, setShow] = useState();
    const [switchInput, setSwitchInput] = useState(true);
    const [allInstitute] = useState(['Semicolon', 'Henley Business School']);
    const [institute, setInstitute] = useState('');
    const [selectedInstitute, setSelectedInstitute] = useState([]);

    // const items = document.querySelectorAll('ul li');
    const toggleData = () => {
        setShow(!show);
    };

    const changeInput = () => {
        setSwitchInput(!switchInput);
    };

    const handleButton = () => {
        setSelectedInstitute([...selectedInstitute, institute]);
        setSwitchInput(!switchInput);
    };

    const handleSelectedInstitute = (name) => {
        if (!selectedInstitute.includes(name)) setSelectedInstitute([...selectedInstitute, name]);
    };

    const removeInstitute = (name) => {
        const temp = [...selectedInstitute];
        const index = temp.indexOf(name);

        temp.splice(index, 1);
        setSelectedInstitute([...temp]);
    };

    const content2 = [
        {
            name: 'Contact Us',
            icon1: Facebook,
            icon2: Twitter,
            icon3: EmailIcon
        }
    ];

    return (
        <div>
            {!isMobile ? (
                <div>
                    <div className="footer-wrapper">
                        <div className="footer-bg">
                            <div className="footer">
                                <div>
                                    <div className="footer-logo-container">
                                        <img className="footer-logo" src={learnspaceLogo} alt="learnspace logo" />
                                    </div>
                                    <div className="footer-info">
                                        <div>
                                            <div>
                                                <p className="footer-header">Quick Link</p>
                                            </div>

                                            <div className="footer-question-container">
                                                <div>
                                                    <a className="footer-question" href="#LearnSpace">
                                                        what is Learnspace
                                                    </a>
                                                </div>

                                                <div>
                                                    <a className="footer-faq" href="#FAQ">
                                                        FAQs
                                                    </a>
                                                </div>
                                                <div>
                                                    <a className="footer-faq" href="#">
                                                        Create Account
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <p className="footer-header">Address</p>
                                            <p className="footer-sub">371 Borno Way, Sabo Yaba, Lagos.Nigeria</p>
                                        </div>

                                        <div>
                                            <Grid
                                                className="footer-socials"
                                                container
                                                mt="5%"
                                                direction="row"
                                                gap={isMobile ? '48px' : '16px'}
                                                alignItems={isMobile ? 'top' : 'left'}
                                                justifyContent={isMobile ? 'space-between' : 'left'}
                                                paddingX={isMobile && '10px'}
                                                maxWidth={isMobile ? '280px' : '400px'}>
                                                {content2.map((nav, index) => (
                                                    <Grid key={index} item>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: isMobile ? 700 : 700,
                                                                fontSize: '15px',
                                                                lineHeight: '21px',
                                                                color: '#333333',
                                                                fontFamily: 'IBM Plex Sans'
                                                            }}>
                                                            {nav.name}
                                                        </Typography>

                                                        {nav.icon1 && (
                                                            <Box
                                                                display="flex"
                                                                flexDirecton="row"
                                                                justify="space-between"
                                                                paddingRight="20px">
                                                                <img className="footer-facebook-icon" src={nav.icon1} alt="" />
                                                                <img src={nav.icon2} alt="" />
                                                                <img className="footer-email-icon" src={nav.icon3} alt="" />
                                                            </Box>
                                                        )}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </div>
                                    </div>
                                </div>

                                <div className="waiting-list">
                                    <p className="footer-contact">Create Student Account</p>

                                    <div>
                                        <div>
                                            <div className="form-group">
                                                <label className="footer-label" htmlFor="name">
                                                    FirstName
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder=""
                                                    required
                                                />
                                                <small id="helpId" className="form-text text-muted" />

                                                <label className="footer-label" htmlFor="name">
                                                    LastName
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="name"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder=""
                                                    required
                                                />
                                                <small id="helpId" className="form-text text-muted" />

                                                <label className="footer-label" htmlFor="email">
                                                    Email
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="email"
                                                    id=""
                                                    aria-describedby="helpId"
                                                    placeholder=""
                                                    required
                                                />
                                                <small id="helpId" className="form-text text-muted" />

                                                <label className="footer-label " htmlFor="Prefered Specialization">
                                                    Preferred Training Institute
                                                </label>

                                                {/* <div style={{ position: 'relative' }}>
                                                    <BsChevronDown className="chevron-down" onClick={toggleData} />
                                                    <div className="prefer-institute">
                                                        {selectedInstitute.map((b, index) => (
                                                            <div
                                                                className={` footerPlus-container ${
                                                                    index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                }`}
                                                                key={b}>
                                                                {b}
                                                                <BsX onClick={() => removeInstitute(b)} className="footer-close" />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    {show ? (
                                                        <div className="prefer-options">
                                                            <div>
                                                                {allInstitute.map((name, index) => (
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => handleSelectedInstitute(name.name)}
                                                                        className={`${
                                                                            index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                        }`}>
                                                                        <p>{name.name}</p>
                                                                    </button>
                                                                ))}
                                                                <div>
                                                                    <BsX onClick={toggleData} className="footer-close-dropdown" />
                                                                </div>
                                                            </div>

                                                            {switchInput ? (
                                                                <p className="footer-plus-wrapper">
                                                                    <span>
                                                                        <BsPlus className="footer-plus" onClick={changeInput} />
                                                                    </span>
                                                                    Add your choice
                                                                </p>
                                                            ) : (
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="input-preferred"
                                                                        onChange={(e) => {
                                                                            setInstitute(e.target.value);
                                                                        }}
                                                                        required
                                                                    />
                                                                    <button className="footer-add-btn" onClick={handleButton}>
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div> */}

                                                <div style={{ position: 'relative' }}>
                                                    <BsChevronDown className="chevron-down" onClick={toggleData} />
                                                    <div className="prefer-institute">
                                                        {selectedInstitute.map((b, index) => (
                                                            <div
                                                                className={` footerPlus-container ${
                                                                    index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                }`}
                                                                key={b}>
                                                                {b}
                                                                <BsX onClick={() => removeInstitute(b)} className="footer-close" />
                                                            </div>
                                                        ))}
                                                    </div>

                                                    {show ? (
                                                        <div className="prefer-options">
                                                            <div>
                                                                {allInstitute.map((name, index) => (
                                                                    <button
                                                                        key={index}
                                                                        onClick={() => handleSelectedInstitute(name)}
                                                                        className={`${
                                                                            index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                        }`}>
                                                                        <p>{name}</p>
                                                                    </button>
                                                                ))}
                                                                <div>
                                                                    <BsX onClick={toggleData} className="footer-close-dropdown" />
                                                                </div>
                                                            </div>

                                                            {switchInput ? (
                                                                <p className="footer-plus-wrapper">
                                                                    <span>
                                                                        <BsPlus className="footer-plus" onClick={changeInput} />
                                                                    </span>
                                                                    Add your choice
                                                                </p>
                                                            ) : (
                                                                <div>
                                                                    <input
                                                                        type="text"
                                                                        className="input-preferred"
                                                                        onChange={(e) => {
                                                                            setInstitute(e.target.value);
                                                                        }}
                                                                        required
                                                                    />
                                                                    <button className="footer-add-btn" onClick={handleButton}>
                                                                        Add
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    ) : null}
                                                </div>

                                                <div>
                                                    <button type="button" id="" className="footer-form-btn">
                                                        Create Account
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="footer-divider"></div>
                            <div>
                                <p className="footer-copyright">© 2022 Learnspace. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div>
                        <div className="footer-wrapper">
                            <div className="footer-bg">
                                <div className="footer">
                                    <div className="waiting-list">
                                        <p className="footer-contact">Create Student Account</p>

                                        <div>
                                            <div>
                                                <div className="form-group">
                                                    <label className="footer-label" htmlFor="name">
                                                        FirstName
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        id=""
                                                        aria-describedby="helpId"
                                                        placeholder=""
                                                        required
                                                    />

                                                    <small id="helpId" className="form-text text-muted" />

                                                    <label className="footer-label" htmlFor="name">
                                                        LastName
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="name"
                                                        id=""
                                                        aria-describedby="helpId"
                                                        placeholder=""
                                                        required
                                                    />
                                                    <small id="helpId" className="form-text text-muted" />

                                                    <label className="footer-label" htmlFor="email">
                                                        Email
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="email"
                                                        id=""
                                                        aria-describedby="helpId"
                                                        placeholder=""
                                                        required
                                                    />
                                                    <small id="helpId" className="form-text text-muted" />

                                                    <label className="footer-label footer-label-name" htmlFor="Prefered Specialization">
                                                        Preferred Training Institute
                                                    </label>

                                                    <div style={{ position: 'relative' }}>
                                                        <BsChevronDown className="chevron-down" onClick={toggleData} />
                                                        <div className="prefer-institute">
                                                            {selectedInstitute.map((b, index) => (
                                                                <div
                                                                    className={` footerPlus-container ${
                                                                        index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                    }`}
                                                                    key={b}>
                                                                    {b}
                                                                    <BsX onClick={() => removeInstitute(b)} className="footer-close" />
                                                                </div>
                                                            ))}
                                                        </div>

                                                        {show ? (
                                                            <div className="prefer-options">
                                                                <div>
                                                                    {allInstitute.map((name, index) => (
                                                                        <button
                                                                            key={index}
                                                                            onClick={() => handleSelectedInstitute(name)}
                                                                            className={`${
                                                                                index % 2 == 0 ? 'footer-plus-wrap' : 'footer-plus-wrapper'
                                                                            }`}>
                                                                            <p>{name}</p>
                                                                        </button>
                                                                    ))}
                                                                    <div>
                                                                        <BsX onClick={toggleData} className="footer-close-dropdown" />
                                                                    </div>
                                                                </div>

                                                                {switchInput ? (
                                                                    <p className="footer-plus-wrapper">
                                                                        <span>
                                                                            <BsPlus className="footer-plus" onClick={changeInput} />
                                                                        </span>
                                                                        Add your choice
                                                                    </p>
                                                                ) : (
                                                                    <div>
                                                                        <input
                                                                            type="text"
                                                                            className="input-preferred"
                                                                            onChange={(e) => {
                                                                                setInstitute(e.target.value);
                                                                            }}
                                                                            required
                                                                        />
                                                                        <button className="footer-add-btn" onClick={handleButton}>
                                                                            Add
                                                                        </button>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ) : null}
                                                    </div>

                                                    <div>
                                                        <button type="button" id="" className="footer-form-btn">
                                                            Create Account
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className="footer-logo-container">
                                            <img className="footer-logo" src={learnspaceLogo} alt="learnspace logo" />
                                        </div>
                                        <div className="footer-info">
                                            <div className="footer-link">
                                                <div>
                                                    <p className="footer-header">Quick Link</p>
                                                </div>

                                                <div>
                                                    <a className="footer-question" href="#LearnSpace">
                                                        what is Learnspace
                                                    </a>
                                                </div>
                                                <div>
                                                    <a className="footer-faq" href="#FAQ">
                                                        FAQs
                                                    </a>
                                                </div>
                                                <div>
                                                    <a className="footer-faq" href="">
                                                        Newsletter
                                                    </a>
                                                </div>
                                            </div>

                                            <div>
                                                <p className="footer-header">Address</p>
                                                <p className="footer-sub">371 Borno Way, Sabo Yaba, Lagos.Nigeria</p>
                                            </div>

                                            <div>
                                                <Grid
                                                    className="footer-socials"
                                                    container
                                                    mt="5%"
                                                    direction="row"
                                                    gap={isMobile ? '48px' : '16px'}
                                                    alignItems={isMobile ? 'top' : 'left'}
                                                    justifyContent={isMobile ? 'space-between' : 'left'}
                                                    paddingX={isMobile && '10px'}
                                                    maxWidth={isMobile ? '280px' : '400px'}>
                                                    {content2.map((nav, index) => (
                                                        <Grid key={index} item>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: isMobile ? 700 : 700,
                                                                    fontSize: '15px',
                                                                    lineHeight: '21px',
                                                                    color: '#333333',
                                                                    fontFamily: 'IBM Plex Sans'
                                                                }}>
                                                                {nav.name}
                                                            </Typography>

                                                            {nav.icon1 && (
                                                                <Box
                                                                    display="flex"
                                                                    flexDirecton="row"
                                                                    justify="space-between"
                                                                    paddingRight="20px">
                                                                    <img className="footer-facebook-icon" src={nav.icon1} alt="" />
                                                                    <img src={nav.icon2} alt="" />
                                                                    <img className="footer-email-icon" src={nav.icon3} alt="" />
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="footer-divider"></div>
                                <div>
                                    <p className="footer-copyright">© 2022 Learnspace. All rights reserved.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Footer;
