import { Button, Typography, Grid, useMediaQuery } from '@mui/material';
import PropTypes from 'prop-types';
import '../../styles/landingPage/startupContent.css';
import { useTheme } from '@mui/material/styles';

function StartupContent(props) {
    const { illustration, desc, descHeader, btns } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {!isMobile ? (
                <Grid className="startupContent-wrapper" textAlign={isMobile ? 'center' : 'left'}>
                    <div className="startupContent-container">
                        <div>
                            <Typography
                                className="imageSlideLeft"
                                fontWeight="700"
                                fontSize="24px"
                                color="#101828"
                                fontFamily="IBM Plex Sans">
                                {descHeader}
                            </Typography>

                            <Typography
                                className="imageSlideLeft"
                                marginTop="20px"
                                marginBottom="20px"
                                lineHeight="30px"
                                fontWeight="400px"
                                color="#667085"
                                width={isMobile ? '100%' : '85%'}
                                fontSize={isMobile ? '12px' : '16px'}>
                                {desc}
                            </Typography>

                            <Button className="imageSlideLeft" disableElevation>
                                {btns}
                            </Button>
                        </div>

                        <div>
                            <Grid container justifyContent={isMobile ? 'center' : 'space-between'} alignItems="center">
                                <Grid mt={isMobile && 5}>
                                    <img
                                        src={illustration}
                                        alt="illus"
                                        width={isMobile ? '100%' : '342px'}
                                        height={isMobile ? '100%' : '80%'}
                                        className="imageSlide"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            ) : (
                <Grid className="startupContent-wrapper" textAlign={isMobile ? 'center' : 'left'}>
                    <div className="startupContent-container">
                        <div>
                            <Typography fontWeight="700" fontSize="24px" color="#101828" fontFamily="IBM Plex Sans">
                                {descHeader}
                            </Typography>

                            <Typography
                                marginTop="20px"
                                marginBottom="20px"
                                lineHeight="30px"
                                fontWeight="400px"
                                color="#667085"
                                width={isMobile ? '100%' : '85%'}
                                fontSize={isMobile ? '12px' : '16px'}>
                                {desc}
                            </Typography>
                        </div>

                        <div>
                            <Grid container justifyContent={isMobile ? 'center' : 'space-between'} alignItems="center">
                                <Grid mt={isMobile && 5}>
                                    <img
                                        src={illustration}
                                        alt="illus"
                                        width={isMobile ? '100%' : '342px'}
                                        height={isMobile ? '100%' : '80%'}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <Button disableElevation>{btns}</Button>
                    </div>
                </Grid>
            )}
        </>
    );
}

StartupContent.propTypes = {
    descHeader: PropTypes.any,
    illustration: PropTypes.string,
    desc: PropTypes.string,
    btns: PropTypes.any
};

export default StartupContent;
