import '../../styles/landingPage/howItWork.css';
import howItWorksImg from '../../assets/landingPageImages/howItWorksImg.svg';

const HowItWorks = () => {
    return (
        <div>
            <div className="work-wrapper">
                <div className="work-container">
                    <div className="work-bg">
                        <div className="work-header-container">
                            <h4 className="work-header">How it works</h4>
                            <div className="work-img-container">
                                <img className="work-img" src={howItWorksImg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
