import Navbar from './navbar-component/Navbar';
import WelcomePage from './welcomePage-component/WelcomePage';
import Partner from './partners-components/Partner';
import LearnspaceIntro from './learnspace-intro-component/LearnspaceIntro';
import ValueOffer from './value-offer-components/ValueOffer';
import StartupStudio from './three-thiers-components/StartupStudio';
import HowItWorks from './how-it-works-components/HowItWorks';
import FAQs from './frequentQuestions-components/FAQs';
import Footer from './footer-components/Footer';

const LandingPage = () => {
    return (
        <div>
            <Navbar />
            <WelcomePage />
            <Partner />
            <LearnspaceIntro />
            <ValueOffer />
            <StartupStudio />
            <HowItWorks />
            <FAQs />
            <Footer />
        </div>
    );
};

export default LandingPage;
