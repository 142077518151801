import * as React from 'react';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery, Paper, Box, List, ListItemText, ListItemButton } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import PropTypes from 'prop-types';

export default function CustomizedList({ question, answer }) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = React.useState(false);
    return (
        <Box sx={{ display: 'flex' }}>
            <ThemeProvider
                theme={createTheme({
                    components: {
                        MuiListItemButton: {
                            defaultProps: {
                                disableTouchRipple: true
                            }
                        }
                    },
                    palette: {
                        mode: 'light',
                        primary: { main: '#F9FAFE' },
                        background: { paper: '#F9FAFE' },
                        fontFamily: 'IBM Plex Sans'
                    }
                })}>
                <Paper
                    elevation={0}
                    sx={{
                        maxHeight: '320px',
                        width: !isMobile ? '60vw' : '80vw',
                        minHeight: isMobile ? '65px' : '70px',
                        borderRadius: '8px',
                        margin: '0 auto',
                        fontFamily: 'IBM Plex Sans'
                    }}>
                    <List component="nav" disablePadding>
                        <Box
                            sx={{
                                bgcolor: open ? '#F9FAFE' : null,
                                pb: open ? 2 : 0,
                                borderRadius: '8px'
                            }}>
                            <ListItemButton
                                alignItems=""
                                onClick={() => setOpen(!open)}
                                sx={{
                                    p: '22px'
                                }}>
                                <ListItemText
                                    primary={question}
                                    primaryTypographyProps={{
                                        fontSize: isMobile ? '15px' : '17px',
                                        fontWeight: '600',
                                        lineHeight: isMobile ? '21px' : '22px',
                                        maxWidth: isMobile ? '100%' : '100%',
                                        fontFamily: 'IBM Plex Sans'
                                    }}
                                />

                                {!open ? (
                                    <Add
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            mr: isMobile ? '12px' : '24px',
                                            transition: '0.8s'
                                        }}
                                    />
                                ) : (
                                    <Remove
                                        sx={{
                                            width: '20px',
                                            height: '20px',
                                            mr: isMobile ? '12px' : '24px',
                                            transition: '0.8s'
                                        }}
                                    />
                                )}
                            </ListItemButton>
                            {open && (
                                <Box
                                    sx={{
                                        borderRadius: '8px',
                                        paddingLeft: isMobile ? 7 : 9,
                                        paddingRight: 3,
                                        maxWidth: isMobile ? '100%' : '100%',
                                        fontFamily: 'IBM Plex Sans'
                                    }}>
                                    <Typography
                                        fontWeight={700}
                                        fontSize={isMobile ? '14px' : '16px'}
                                        lineHeight={isMobile ? '23px' : '24px'}
                                        color="#333333"
                                        fontFamily="IBM Plex Sans">
                                        {answer}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </List>
                </Paper>
            </ThemeProvider>
        </Box>
    );
}

CustomizedList.propTypes = {
    question: PropTypes.string,
    answer: PropTypes.string
};
