import * as React from 'react';
import PropTypes from 'prop-types';
import '../../styles/landingPage/startupContent.css';
import { Tab, Tabs, Typography, Box, Grid, useMediaQuery } from '@mui/material';
import StartupContent from './StartupContent';
import content from './content';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
    tabs: {
        '& .MuiButtonBase-root.MuiTab-root': {
            alignItems: 'center',
            justifyContent: 'center',
            width: '33%',
            fontWeight: '700',
            fontSize: '20px',
            textAlign: 'center',
            borderRadius: '8px',
            height: '50px',
            padding: '12px, 20px',
            textTransform: 'none',
            fontFamily: 'IBM Plex Sans'
        },
        '& 	.Mui-selected': {},
        '& 	.MuiSelect-select': {
            width: '295px',
            height: '40px',
            borderRadius: '2px',
            paddingX: '20px',
            paddingY: '10px',
            borderBottom: '2.5px solid #0EAA4F',
            fontWeight: 700,
            textAlign: 'center',
            color: '#0EAA4F',
            backgroundColor: '#0EAA4F',
            fontFamily: 'IBM Plex Sans'
        }
    }
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

function BasicTabs() {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const handleChange = (event, newValue) => {
        console.log(newValue);
        setValue(isMobile ? event.target.value : newValue);
    };

    const classes = useStyles();
    return (
        <Grid my="5%" container alignItems="center" justifyContent="center" height="80vh" width="100%" backgroundColor="#FFFFF">
            <Grid item textAlign="center">
                {isMobile ? (
                    <Grid>
                        <div className="trainee-footer">
                            <p className="trainee-header">Trainee</p>
                        </div>
                        <TabPanel value={value} index={0}>
                            <StartupContent
                                descHeader={content[0].heading}
                                illustration={content[0].img}
                                desc={content[0].description}
                                btns={content[0].button1}
                            />
                        </TabPanel>

                        <TabPanel>
                            <p className="trainee-header2">Institution</p>
                            <StartupContent
                                descHeader={content[1].heading}
                                illustration={content[1].img}
                                desc={content[1].description}
                                btns={content[1].button2}
                            />
                        </TabPanel>

                        <TabPanel>
                            <p className="trainee-header3">Investor</p>
                            <StartupContent
                                descHeader={content[2].heading}
                                illustration={content[2].img}
                                desc={content[2].description}
                                btns={content[2].button3}
                            />
                        </TabPanel>
                    </Grid>
                ) : (
                    <div>
                        <Tabs
                            className={classes.tabs}
                            orientation="horizontal"
                            value={value}
                            onChange={handleChange}
                            aria-label="basic tabs example"
                            TabIndicatorProps={{ sx: { backgroundColor: '#0EAA4F', height: 4 } }}
                            sx={{
                                borderBottom: '2.5px solid ',
                                borderColor: '#E0E0E0',
                                width: '80%',
                                margin: '0 auto',
                                marginBottom: '3%',
                                '& button : ': { borderRadius: '12px 12px 0px 0px;' },
                                '& button.Mui-selected': { backgroundColor: 'rgba(14, 170, 79, 0.05)', color: 'green' }
                            }}>
                            <Tab className="tabs-section" label="Trainee" {...a11yProps(0)} />
                            <Tab
                                label="Institution"
                                {...a11yProps(1)}
                                // sx={{ '& button.Mui-selected': { backgroundColor: `${value === 1 && 'blue'}` } }}
                            />
                            <Tab
                                label="Investor"
                                {...a11yProps(2)}
                                //  sx={{ backgroundColor: `${value === 2 && 'pink'}` }}
                            />
                        </Tabs>

                        <Grid sx={{ display: 'flex' }} item lg={12} xl={12} sm={12} md={12} xs={12}>
                            <TabPanel value={value} index={0}>
                                <StartupContent
                                    descHeader={content[0].heading}
                                    illustration={content[0].img}
                                    desc={content[0].description}
                                    btns={content[0].button1}
                                />
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <StartupContent
                                    descHeader={content[1].heading}
                                    illustration={content[1].img}
                                    desc={content[1].description}
                                    btns={content[1].button2}
                                />
                            </TabPanel>

                            <TabPanel value={value} index={2}>
                                <StartupContent
                                    descHeader={content[2].heading}
                                    illustration={content[2].img}
                                    desc={content[2].description}
                                    btns={content[2].button3}
                                />
                            </TabPanel>
                        </Grid>
                    </div>
                )}
            </Grid>
        </Grid>
    );
}
BasicTabs.propTypes = {
    text: PropTypes.string,
    icon: PropTypes.object
};
export default BasicTabs;
