import '../../styles/landingPage/learnspaceIntro.css';

const LearnspaceIntro = () => {
    return (
        <div>
            <div className="intro-wrapper">
                <div className="intro-container">
                    <div className="intro-bg">
                        <div className="intro-content">
                            <div className="intro-header-content" id="LearnSpace">
                                <h4 className="intro-header">What is Learnspace?</h4>
                            </div>

                            <div className="intro-subheader-content">
                                <h4 className="intro-subheader">
                                    We are an education financing company that provides loan to students attending pre-approved
                                    institutions. We do this by matching students’ loan needs with investors’ funds and offering them the
                                    best rates for acquiring high-in-demand skills.
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LearnspaceIntro;
