/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import '../../styles/landingPage/navbar.css';
import learnspaceLogo from '../../assets/landingPageImages/learnspaceLogo.svg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FaBars } from 'react-icons/fa';
// import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import React from 'react';

const drawerWidth = 240;

const Navbar = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const [state, setState] = React.useState(false);
    const toggleDrawer = (open) => (event) => {
        setState(open);
    };

    const list = () => (
        <div onClick={toggleDrawer(false)}>
            <List
                style={{
                    fontFamily: 'IBM Plex Sans',
                    fontWeight: '700',
                    fontSize: '18px',
                    lineHeight: '40px',
                    textTransform: 'capitalize',
                    marginTop: '50px'
                }}>
                <ListItem>
                    <a style={{ textDecoration: 'none', color: '#72757A' }} href="https://app.learnspace.africa/login">
                        Login
                    </a>
                </ListItem>
                <ListItem>
                    <a style={{ textDecoration: 'none', color: '#72757A' }} href="https://app.learnspace.africa/register">
                        Create an account
                    </a>
                </ListItem>
                <ListItem id="FAQ" style={{ color: '#72757A' }}>
                    FAQs
                </ListItem>
            </List>
        </div>
    );

    return (
        <div>
            {!isMobile ? (
                <div className="navbar-wrapper" style={{ position: 'fixed', top: '0', zIndex: '999' }}>
                    <div className="navbar-bg">
                        <div className="navbar-container">
                            <div className="navbar-contents">
                                <div className="logo-contained">
                                    <img className="logo" src={learnspaceLogo} alt="learnspace logo" />
                                </div>

                                <div className="landingbtn-container">
                                    <a href="https://app.learnspace.africa">
                                        <button className="login-btn ">Login</button>
                                    </a>

                                    <a href="https://app.learnspace.africa">
                                        <button className="create-acc-btn">Create an account</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="navbar-wrapper">
                    <div className="navbar-bg" style={{ position: 'fixed', top: '0', zIndex: '999' }}>
                        <div className="navbar-container">
                            <div className="navbar-contents">
                                <div className="logo-contained">
                                    <a href="learnspace.africa">
                                        <img className="logo" src={learnspaceLogo} alt="learnspace logo" />
                                    </a>
                                </div>
                                <div className="menubar">
                                    <FaBars onClick={toggleDrawer(true)} />
                                    <Drawer anchor={'right'} open={state} onClose={toggleDrawer(false)}>
                                        {list()}
                                    </Drawer>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            ;
        </div>
    );
};

export default Navbar;
