import studentImg from '../../assets/landingPageImages/studentImg.svg';
import institutionImg from '../../assets/landingPageImages/institutionImg.svg';
import investorImg from '../../assets/landingPageImages/investorImg.svg';
import StudentButton from './StudentButton';
import InstituteButton from './InstituteButton';
import InvestorButton from './InvestorButton';

const content = [
    {
        img: studentImg,
        heading: 'Learnspace for Students',
        description:
            'As a student, you get access to secured education loans so you can learn now and pay later.' +
            ' You do not need to start repaying until after you have finished studying.',
        button1: <StudentButton />
    },

    {
        img: institutionImg,
        heading: 'Learnspace for Institutions',
        description:
            'We help you to remove the financial barrier learners face,' +
            'thereby increasing your enrolment and focus on what really matters — quality knowledge delivery.',
        button2: <InstituteButton />
    },

    {
        img: investorImg,
        heading: 'Learnspace for Investors',
        description:
            'Investing in Learnspace will add to your long term wealth, but more importantly,' +
            'you get to support the aspirations and dreams of people that are limited by finance.',
        button3: <InvestorButton />
    }
];
export default content;
