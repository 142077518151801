import '../../styles/landingPage/welcomePage.css';
import welcomePageImage from '../../assets/landingPageImages/welcomePageImage.svg';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const WelcomePage = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
            {!isMobile ? (
                <div>
                    <div className="welcome-wrapper" style={{ marginTop: '60px' }}>
                        <div className="hero-msg-container">
                            <div className="welcome-container">
                                <div className="hero-group">
                                    <div className="hero-header-contents">
                                        <p className="landinghero-header">
                                            Fund Your Dreams with <span> Ease </span>
                                        </p>
                                    </div>

                                    <div className="hero-subheader-contents">
                                        <p className="landinghero-subheader">
                                            Accelerate your career and acquire high-in-demand skills with Learnspace’s easy-to-access
                                            education financing.
                                        </p>
                                    </div>

                                    <div className="landingbtn-container">
                                        <a href="https://learnspace-africa-dev.vercel.app/register">
                                            <button className="create-acc-btn">Create an account</button>
                                        </a>

                                        <a href="https://learnspace-africa-dev.vercel.app/login">
                                            <button className="login-btn" style={{ marginLeft: '25px' }}>
                                                Login
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="welcomePage-img">
                                    <img className="welcome-img" src={welcomePageImage} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="welcome-wrapper" style={{ marginTop: '60px' }}>
                        <div className="hero-msg-container">
                            <div className="welcome-container">
                                <div className="hero-group">
                                    <div className="hero-header-contents">
                                        <p className="landinghero-header">
                                            Fund Your Dreams with <span> Ease </span>
                                        </p>
                                    </div>

                                    <div className="hero-subheader-contents">
                                        <p className="landinghero-subheader">
                                            Accelerate your career. Acquire high-in-demand skills with Learnspace’s easy-to-access education
                                            financing.
                                        </p>
                                    </div>
                                </div>
                                <div className="welcomePage-img">
                                    <img className="welcome-img" src={welcomePageImage} alt="" />

                                    <div className="landingbtn-container">
                                        <a href="https://learnspace-africa-dev.vercel.app/login">
                                            <button className="login-btn">Login</button>
                                        </a>

                                        <a href="https://learnspace-africa-dev.vercel.app/register">
                                            <button className="create-acc-btn">Create an account</button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default WelcomePage;
