import { Box, Stack } from '@mui/material';
import CustomizedList from './QnAList';
import '../../styles/landingPage/faq.css';

const q1 = [
    'Who can apply to get a loan from LearnSpace ?',
    'A student that has received an offer of admission into an approved program from one of our educational institution partners.'
];
const q2 = [
    'How do I access a loan from LearnSpace?',
    'Show the need for an educational loan through your institution after you must have been offered admission into their approved training program and passed all screening processes.'
];
const q3 = [
    'What kind of educational institution can partner with LearnSpace ? ',
    'An educational Institution that trains for high-in-demand skill acquisition in Africa.'
];
const q4 = [
    'How can my institution join and benefit from LearnSpace ?',
    'You can join by selecting the “create account” button at the top and we will help you increase enrolment and secure fees payment.'
];
const q5 = [
    'Who can join and invest in the educational loan product ?',
    'A qualified person or organization looking to grow long-term wealth while creating a great impact on the society. '
];

const q6 = [
    'How can I or my organization join and benefit from LearnSpace ?',
    'You can join by selecting the “create account” button at the top, register as an individual or an organization.'
];

function FAQs() {
    return (
        <div className="faq-bg" id="FAQ">
            <div className="faq-header-container">
                <h4 className="faq-header">How may we help you?</h4>
                <h5 className="faq-sub">Here are answers to our most frequently asked questions</h5>
            </div>
            <Box sx={{ mt: '48px' }}>
                <Stack direction="column" spacing="20px" className="faq-questions">
                    <CustomizedList className="faq-question" question={q1[0]} answer={q1[1]} />
                    <CustomizedList className="faq-question" question={q2[0]} answer={q2[1]} />
                    <CustomizedList className="faq-question" question={q3[0]} answer={q3[1]} />
                    <CustomizedList className="faq-question" question={q4[0]} answer={q4[1]} />
                    <CustomizedList className="faq-question" question={q5[0]} answer={q5[1]} />
                    <CustomizedList className="faq-question" question={q6[0]} answer={q6[1]} />
                </Stack>
            </Box>
        </div>
    );
}

export default FAQs;
