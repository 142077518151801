import '../../styles/landingPage/valueOffer.css';
import bookIcon from '../../assets/landingPageImages/bookIcon.svg';
import graduatCapIcon from '../../assets/landingPageImages/graduatCapIcon.svg';
import suitcaseIcon from '../../assets/landingPageImages/suitcaseIcon.svg';

const ValueOffer = () => {
    return (
        <div>
            <div className="value-wrapper">
                <div className="value-container">
                    <div className="value-bg">
                        <div className="value-header-content">
                            <h4 className="value-header">Values we offer</h4>
                        </div>

                        <div className="section-one-container">
                            <div className="section-one">
                                <img className="sectionOne-img" src={bookIcon} alt="" />
                                <h4 className="sectionOne-header">Get easy education financing</h4>
                                <h5 className="sectionOne-sub">For trainee:</h5>
                                <h6 className="sectionOne-description">
                                    Whatever course or skills you want to acquire, access Learnspace loans so your financial situation does
                                    not define your future.
                                </h6>
                            </div>
                            <div className="dashed-lines"></div>

                            <div className="section-one">
                                <img className="sectionOne-img" src={graduatCapIcon} alt="" />
                                <h4 className="sectionOne-header">Never lose bright students</h4>
                                <h5 className="sectionOne-sub">For learning instituitons:</h5>
                                <h6 className="sectionOne-description">
                                    Get them back on track by helping them access easy educational loans. Just secure the enrolments, while
                                    we secure the fees for you.
                                </h6>
                            </div>

                            <div className="dashed-lines"></div>

                            <div className="section-one">
                                <img className="sectionOne-img" src={suitcaseIcon} alt="" />
                                <h4 className="sectionOne-header">Make impactful investments</h4>
                                <h5 className="sectionOne-sub">For investors:</h5>
                                <h6 className="sectionOne-description">
                                    Make impactful investments while earning money and also help trainees remove the financial barriers that
                                    hinders their dreams.
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ValueOffer;
