import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingPage from 'landingPageSection/LandingPage';

const App = () => {
    return (
        <>
            <Router>
                <Routes>
                    <Route exact path="/" element={<LandingPage />} />
                </Routes>
            </Router>
        </>
    );
};

export default App;
