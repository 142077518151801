import { Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

const InvestorButton = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div>
            <Button
                variant="contained"
                fullWidth={isMobile && true}
                sx={{
                    backgroundColor: '#0EAA4F',
                    marginTop: '12px',
                    padding: '12px 10px',
                    fontSize: '14px',
                    fontWeight: '700',
                    width: `${isMobile ? '90vw' : '138px'}`,
                    height: `${isMobile ? '48px' : '48px'}`,
                    color: '#fff',
                    fontFamily: 'IBM Plex Sans',
                    borderRadius: '4px',
                    textTransform: 'none',
                    alignItems: `${isMobile ? 'flex-start' : 'center'}`,
                    '&:hover': { backgroundColor: '#0EAA4F' }
                }}>
                Get started
            </Button>
        </div>
    );
};

export default InvestorButton;
