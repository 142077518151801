import '../../styles/landingPage/partner.css';
import henleyLogo from '../../assets/landingPageImages/henleyLogo.svg';
import CowrywiseLogo from '../../assets/landingPageImages/CowrywiseLogo.svg';
import semicolonLogo from '../../assets/landingPageImages/semicolonLogo.svg';
import FCMBLogo from '../../assets/landingPageImages/FCMBLogo.svg';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

const Partner = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <div>
            {!isMobile ? (
                <div className="partner-wrapper">
                    <div className="partner-container">
                        <div className="partner-bg">
                            <div className="partner-contents">
                                <div className="partner-header">
                                    <p>Partners</p>
                                </div>
                                <div className="partner-logos">
                                    <img className="henley-img" src={henleyLogo} alt="" />
                                    <img className="cowry-img" src={CowrywiseLogo} alt="" />
                                    <img className="semicolon-img" src={semicolonLogo} alt="" />
                                    <img className="fcmb-img" src={FCMBLogo} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="partner-wrapper">
                        <div className="partner-container">
                            <div className="partner-bg">
                                <div className="partner-contents">
                                    <div className="partner-header">
                                        <p>Partners</p>
                                    </div>
                                    <div className="">
                                        <div className=" mobile-partner-logos ">
                                            <img className="henley-img" src={henleyLogo} alt="" />
                                            <img className="fcmb-img" src={FCMBLogo} alt="" />
                                        </div>
                                        <div className=" mobile-partner-logos">
                                            <img className="semicolon-img" src={semicolonLogo} alt="" />
                                            <img className="cowry-img" src={CowrywiseLogo} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Partner;
